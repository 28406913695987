import get from 'lodash/get';
import { createSlice } from '@reduxjs/toolkit';

import { CustomerState } from 'types/customer.types';
import CustomerAction from 'store/actions/customer.action';

export const CustomerInitialState: CustomerState = {
  data: undefined,
  list: [],
  total: 0,
  fetching: false,
  fetched: false,
  error: null,
};

export type CustomerType = typeof CustomerInitialState;

const handleCustomer = (state: CustomerState, action: any) => {
  const { customer = null, next, previous } = get(action, 'payload.data');
  return {
    ...state,
    data: customer,
    next,
    previous,
  };
};

const customerReducer = createSlice({
  name: 'Customer',
  initialState: CustomerInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CustomerAction.customerFindAll.fulfilled, (state, action) => {
        const { customers: list = [], total = 0 } = get(action, 'payload.data', {customers: [], total: 0});
        return {
          ...state,
          list,
          total,
        };
      })
      .addCase(CustomerAction.customerUpdate.fulfilled, handleCustomer)
      .addCase(CustomerAction.customerFindByID.fulfilled, handleCustomer)
      .addCase(CustomerAction.customerFindByCode.fulfilled, handleCustomer)
      .addCase(CustomerAction.customerFindFirst.fulfilled, handleCustomer)
      .addCase(CustomerAction.customerFindLast.fulfilled, handleCustomer)
      .addCase(CustomerAction.customerClearData, (state) => ({
        ...state,
        data: undefined,
        next: undefined,
        previous: undefined,
      }));
  },
});

export default customerReducer.reducer;
