import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import request from 'utilities/makeRequest';
import { ITable } from 'constants/interface';

import {
  ICustomer,
  ICustomers,  ICustomerUpdate,
  // CUSTOMER_CREATE,
  CUSTOMER_UPDATE,
  CUSTOMER_FIND_BY_ID,
  CUSTOMER_FIND_BY_CODE,
  CUSTOMER_FIND_FIRST,
  CUSTOMER_FIND_LAST,
  CUSTOMER_FIND_ALL,
  CUSTOMER_CLEAR_DATA,
} from 'types/customer.types';

// const customerCreate = createAsyncThunk<ICustomer, ICustomerCreate>(CUSTOMER_CREATE, async (data) => request.post('/customers', { ...data }));
const customerUpdate = createAsyncThunk<ICustomer, ICustomerUpdate>(CUSTOMER_UPDATE, async (data) =>
  request.put(`/customers/id/${data.id}`, { ...data })
);
const customerFindByID = createAsyncThunk<ICustomer, string, any>(CUSTOMER_FIND_BY_ID, async (id, query) =>
  request.get(`/customers/id/${id}`, query)
);
const customerFindByCode = createAsyncThunk<ICustomer, string>(CUSTOMER_FIND_BY_CODE, async (code) => request.get(`/customers/${code}`));
const customerFindFirst = createAsyncThunk<ICustomer>(CUSTOMER_FIND_FIRST, async () => request.get('/customers/find/first'));
const customerFindLast = createAsyncThunk<ICustomer>(CUSTOMER_FIND_LAST, async () => request.get('/customers/find/last'));
const customerFindAll = createAsyncThunk<ICustomers, Partial<ITable>>(CUSTOMER_FIND_ALL, async (query) => request.get('/customers', query));
const customerClearData = createAction(CUSTOMER_CLEAR_DATA);

const CustomerActions = {
  // customerCreate,
  customerUpdate,
  customerFindByID,
  customerFindByCode,
  customerFindFirst,
  customerFindLast,
  customerFindAll,
  customerClearData,
};

export default CustomerActions;
